import { ModelSelect } from 'vue-search-select';
import DatePicker from 'vue2-datepicker';
export default {
  name: 'addCustTanMapping',
  components: {
    ModelSelect,
    DatePicker
  },
  data() {
    return {
      unsubscribe: null,
      custId: null,
      custSiteId: null,
      custSiteName: null,
      custTanNumber: null,
      start_date_end_date: null,
      startDate: null,
      endDate: null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          alert('Add');
        }
        if (actionName === 'update') {
          alert('Update');
        }
        if (actionName === 'save') {
          alert('Save');
        }
        if (actionName === 'delete') {
          alert('Delete');
        }
        if (actionName === 'upload') {
          alert('Upload');
        }
        if (actionName === 'download') {
          alert('Download');
        }
      }
    });
  },
  methods: {},
  beforeDestroy() {
    this.unsubscribe();
  }
};
