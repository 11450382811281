import ExcelDetails from '../addUploadExcel/uploadExcelDetails';
import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addUploadExcel',
  components: { ExcelDetails, appStrings },
  data() {
    return {
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      showExcelModal: false,
      showExcelDetailsModal: false,
      excelRowDtl: null,
      uploadExcelData: [],
      selectedFilesArr: [],
      uploadExcelFields: [
        {
          key: 's_no',
          label: 'Serial No.'
        },
        {
          key: 'excel_name'
        },
        {
          key: 'sheet_name'
        },
        {
          key: 'row_uploaded'
        },
        {
          key: 'mapped_column'
        },
        {
          key: 'updated_by'
        },
        {
          key: 'updation_date'
        },
        {
          key: 'delete'
        }
      ]
    };
  },
  mounted() {},
  methods: {
    selectFile() {
      this.selectedFilesArr.push(this.$refs.file.files);
      this.saveFileToTempTable(this.$refs.file.files.item(0));
    },
    browseFile() {
      this.$refs.file.click();
    },
    uploadFile() {
      const payload = {
        excel_import_hdr_id: this.uploadExcelData[0].excel_improt_hdr_id,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 0
      };
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/postTempDataList', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    importExcelUpload() {},
    saveExcelUpload() {},
    closeExcelUpload() {
      this.showExcelModal = false;
    },
    saveFileToTempTable(fileName) {
      let formData = new FormData();
      formData.append('file', fileName);
      formData.append('menu_id', this.$store.state.shared.menuId);
      formData.append('sub_menu_id', 0);
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/addUploadExcelList', formData)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.uploadExcelData = results;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadFile() {},
    removeRow(index) {
      this.uploadExcelData.splice(index, 1);
    },
    showExcelDetails(flag, rowData, index) {
      this.showExcelDetailsModal = flag;
      this.excelRowDtl = rowData;
      this.sheetIndex = index;
    },
    closeExcelDetail(columns) {
      if (columns || columns === 0) {
        this.uploadExcelData[this.sheetIndex].mapped_column = columns;
      }
      this.showExcelDetails(false);
    }
  },
  beforeDestroy() {
    // this.unsubscribe();
  }
};
