import commonHelper from '@/app/utility/common.helper.utility';
import store from '../../../../store';
import excelUploadEntry from '../twoSixASUpload/addUploadExcel';
import custTanAdd from './addCustTanMapping';
export default {
  name: 'custTanMapping',
  components: {
    commonHelper,
    store,
    excelUploadEntry,
    custTanAdd
  },
  watch: {
    currentPage: function() {
      this.getCustomerTanData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getCustomerTanData();
    }
  },
  data() {
    return {
      unsubscribe: null,
      loader: false,
      showExcelUploadModal: false,
      showChildModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      custName: {
        value: null,
        text: null
      },
      custSiteName: {
        value: null,
        text: null
      },
      custTanNumber: {
        value: null,
        text: null
      },
      customerData: [],
      customerFields: [
        {
          key: 'customer_name'
        },
        {
          key: 'site_name'
        },
        {
          key: 'pan_no'
        },
        {
          key: 'tan_no'
        },
        {
          key: 'active'
        }
      ]
    };
  },
  mounted() {
    this.getCustomerTanData();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showChildModal = true;
        }
        if (actionName === 'download') {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'twoSixASUpload/getCustomerTanData',
            'customer-tan-data',
            () => (this.loader = false)
          );
        }
        if (actionName === 'print') {
          this.showExcelUploadModal = true;
        }
      }
    });
  },
  methods: {
    getCustomerTanData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        customer_name: this.custName.text,
        site_name: this.custSiteName.text,
        tan: this.custTanNumber.text
      };
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/getCustomerTanData', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.customerData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    searchTaxGroup() {
      this.getCustomerTanData();
    },
    clearTaxGroup() {
      this.custName.text = null;
      this.custName.value = null;
      this.custSiteName.text = null;
      this.custSiteName.value = null;
      this.custTanNumber.text = null;
      this.custTanNumber.value = null;
      this.customerData = [];
    },
    hideChildModal() {
      this.showChildModal = false;
    },
    openCloseExcelUploadModal(flag) {
      this.showExcelUploadModal = flag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
