import { ModelSelect, BasicSelect } from 'vue-search-select';
import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'uploadExcelDetails',
  components: { ModelSelect, BasicSelect },
  props: ['excelRowDtl'],
  data() {
    return {
      loader: false,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      colMapIndex: 0,
      startRow: 1,
      endRow: null,
      uploadExcelData: [],
      uploadExcelFields: [],
      mappingFieldsList: []
    };
  },
  mounted() {
    this.getMappingColumns();
    this.getDataForMappingColumns();
  },
  methods: {
    getMappingColumns() {
      const payload = {
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 0
      };
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/getMappingColumnsForExcelDetail', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const excelDetailsColumn = response.data.data;
            this.mappingFieldsList = excelDetailsColumn.map(item => {
              return {
                value: 'column' + item.column_id,
                text: item.display_name
              };
            });
            this.uploadExcelFields = excelDetailsColumn.map((key, index) => {
              return {
                key: 'column' + (index + 1),
                label: key.display_name
              };
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getDataForMappingColumns() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        excel_import_hdr_id: this.excelRowDtl.excel_improt_hdr_id,
        menu_id: this.$store.state.shared.menuId,
        sub_menu_id: 0
      };
      this.loader = true;
      this.$store
        .dispatch(
          'twoSixASUpload/getMappingColumnsDataListForExcelDetail',
          payload
        )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.totalRows = response.data.data.total_elements;
            this.uploadExcelData = response.data.data.page;
            this.endRow = this.totalRows;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    changedColumn(item, index) {
      this.colMapIndex = Number(item.slice(item.indexOf('n') + 1));
      this.uploadExcelFields[this.colMapIndex - 1].key = 'column' + (index + 1);
    },
    mapRowTable() {
      const details = this.uploadExcelFields.map(item => {
        return {
          columnNum: this.findColSequence(item.key),
          excel_imp_mpng_column_id: this.findMappingColId(item.key)
        };
      });
      const payload = {
        end_row: this.endRow,
        excel_import_hdr_id: this.excelRowDtl.excel_improt_hdr_id,
        excel_import_sheet_id: this.excelRowDtl.excel_import_sheet_id,
        start_row: this.startRow,
        mapping_details: details
      };
      this.loader = true;
      this.$store
        .dispatch('twoSixASUpload/postMappedColumnsForExcel', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.noOfMappedColumns = response.data.data.mapped_column;
            this.$emit('closeExcelDetail', this.noOfMappedColumns);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    findColSequence(name) {
      return name.slice(name.indexOf('n') + 1);
    },
    findMappingColId(name) {
      return Number(name.slice(name.indexOf('n') + 1));
    },
    closeTable() {
      this.$emit('closeExcelDetail');
    }
  },
  beforeDestroy() {
    // this.unsubscribe();
  }
};
